#AboutUs {
  background-color: #0b0b0f;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 100px;

  /****************************************************AboutUs***************************************************/

  #Banner {
    height: 100vh;

    .bg-stars {
      background-image: url("../../assets/bg-images/bg-stars.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      background-attachment: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;

      @media (max-width: 768px) {
        background-attachment: scroll;
      }
    }

    .img-container {
      height: 100vh;
      width: 100%;
      position: relative;

      .planet-wrapper {
        height: 500px;
        width: 500px;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 45%;

        @media (max-width: 768px) {
          top: 40%;
        }

        .title {
          position: absolute;
          font-weight: 600;
          text-align: center;
          font-size: 80px;
          letter-spacing: 2px;

          left: 50%;
          top: 82%;
          transform: translateX(-50%);

          @media (max-width: 768px) {
            font-size: 45px;
            top: 65%;
          }
        }

        .img-wrapper {
          position: absolute;
          top: 45%;
          left: 50%;
          border-radius: 50%;
          box-shadow: rgba(255, 255, 255, 0.17) 0px -23px 25px 0px inset,
            rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
            rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
            rgba(236, 221, 221, 0.06) 0px 2px 1px,
            rgba(247, 247, 247, 0.09) 0px 4px 2px,
            rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
            rgba(0, 0, 0, 0.09) 0px 32px 16px;
          height: 400px;
          width: 500px;
          transform: translateY(-50%) translateX(-50%);

          @media (max-width: 768px) {
            height: 250px;
            width: 300px;
            top: 40%;
          }
        }

        .planet,
        .text {
          position: absolute;
          height: auto;
          left: 50%;

          &.text {
            transform: translateX(-50%) translateY(0);
            top: -50px;
            width: 480px;

            @media (max-width: 768px) {
              width: 300px;
              top: 6%;
            }
          }

          &.planet {
            width: 100%;
            position: relative;
            transform: translateX(-50%);
            animation: floating 5s infinite;
            top: 12%;

            @keyframes floating {
              0% {
                transform: translate(-50%, -0%);
              }
              50% {
                transform: translate(-52%, -6%);
              }
              100% {
                transform: translate(-50%, -0%);
              }
            }

            @media (max-width: 768px) {
              width: 350px;
            }
          }

          @media (max-width: 768px) {
            width: 350px;
          }
        }
      }
    }
  }

  #WhatIs {
    height: fit-content;

    .title-WhatIs,
    .WhatIs-gradient {
      font-size: 70px;
      color: white;
      font-weight: bold;
      padding-top: 80px;

      @media (max-width: 1024px) {
        font-size: 60px;
      }

      @media (max-width: 768px) {
        font-size: 50px;
      }

      &.WhatIs-gradient {
        background: linear-gradient(180deg, #18c8ff 35%, #933ffe 65%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }

    .social-media-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      .img-wrapper {
        width: fit-content;

        img {
          transition: all 0.3s;
          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }
  }

  #Vision {
    height: fit-content;

    .title-vision,
    .vision-gradient {
      font-size: 70px;
      color: white;
      font-weight: bold;
      padding-top: 80px;

      @media (max-width: 1024px) {
        font-size: 60px;
      }

      @media (max-width: 768px) {
        font-size: 50px;
      }

      &.vision-gradient {
        background: linear-gradient(180deg, #18c8ff 35%, #933ffe 65%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }

    .social-media-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      .img-wrapper {
        width: fit-content;

        img {
          transition: all 0.3s;
          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }
  }

  #Team {
    .title-team,
    .team-gradient {
      font-size: 70px;
      color: white;
      font-weight: bold;
      text-align: center;
      padding-top: 100px;

      @media (max-width: 1024px) {
        font-size: 60px;
      }

      @media (max-width: 768px) {
        font-size: 50px;
      }

      &.team-gradient {
        background: linear-gradient(180deg, #18c8ff 35%, #933ffe 65%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }
  }

  #TeamCards {
    border: 1px solid #1ba0c990;
    box-shadow: 0 3px 10px #1ba0c915;
    margin: 5px;
    height: 100%;
    border-radius: 25px;
    background-color: #1a1b23;
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;
    width: 100%;

    &:hover {
      transform: scale(1.005);
      box-shadow: 0 3px 15px #1ba0c932;
    }

    .card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .photo {
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;

          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .name {
        background: linear-gradient(180deg, #18c8ff 18%, #933ffe 82%);
        background-clip: text;
        color: transparent;
        font-weight: 500;
        margin-bottom: 0;
        width: fit-content;
        font-size: 22px;
        font-weight: bold;

        @media (max-width: 500px) {
          padding-top: 10px;
          font-size: 16px;
        }
      }

      .job {
        font-size: 15px;

        @media (max-width: 500px) {
          font-size: 15px;
        }
      }

      .linkedinImg {
        width: 36px;
        height: auto;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          transform: scale(1.01) translateY(-10%);
        }
      }
    }
  }

  #RolesCards {
    border: 1px solid #ffffff;
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.15);
    margin: 5px;
    height: 100%;
    border-radius: 25px;
    background-color: #1a1b23;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 3px 10px rgba(255, 255, 255, 0.329);
    }

    .card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding-left: 24px;
      padding-right: 24px;

      @media (max-width: 500px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .card-title {
        font-size: 32px;
        font-weight: bolder;
        padding-top: 20px;

        @media (max-width: 500px) {
          padding-top: 5px;
          font-size: 21px;
        }
      }

      .card-sub-title {
        background: linear-gradient(185deg, #18c8ff 14.89%, #933ffe 85.85%);
        background-clip: text;
        color: transparent;
        font-weight: 500;
        padding-top: 20px;
        margin-bottom: 0;
        width: fit-content;

        @media (max-width: 500px) {
          padding-top: 10px;
          font-size: 16px;
        }
      }

      .card-text {
        font-size: 16px;
        padding-top: 15px;

        @media (max-width: 500px) {
          font-size: 15px;
        }
      }

      .button-card {
        transition: all 0.3s;
        width: fit-content;
        padding-top: 80px;
        padding-bottom: 48px;

        @media (max-width: 500px) {
          padding-top: 15px;
          padding-bottom: 25px;
        }

        &:hover {
          transform: scale(1.05);
        }
        a {
          text-decoration: none;
          color: white;
          padding: 15px 25px;
          border-radius: 10px;
          background: linear-gradient(200deg, #18c8ff 14.89%, #933ffe 85.85%);
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          pointer-events: none;

          @media (max-width: 500px) {
            font-size: 16px;
            padding: 10px 18px;
          }
        }
      }
    }
  }
}
