#NotFound{
    background-color: #0B0B0F;
    width: 100%;
    overflow: hidden;

  .error-wrapper{
      width: fit-content;

    .content{
      color: white;
      font-weight: 900;
      font-size: 80px;
      text-align: center;
      width: fit-content;

      &::before{
          content: "";
          position: absolute;
          border-bottom: 3px solid white;
          left: 0;
          bottom: 0;
          color: white;
          width: 0%;
          transition: all 0.5s;
      }

      &::after{
          content: "";
          position: absolute;
          border-bottom: 3px solid white;
          right: 0;
          bottom: 1;
          color: white;
          width: 0%;
          transition: all 0.5s;
      }

      &:hover::after,
      &:hover::before{
          width: 100%;
      }
    }
  }

  .content-subtitle{
    color: white;
    font-size: 25px;
  }

  .error-img{
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.500);
    transition: all 0.3s;
    min-width: 200px;
    max-width: 500px;
    height: auto;

    &:hover{
      transform: scale(1.005);
      box-shadow: 0 3px 10px rgb(255, 255, 255, 0.750);
    }
  }
}