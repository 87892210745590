#Header {
  height: fit-content;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9990;

  .modal-wallet-wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    display: none;
    transition: all 0.3s;
    background: linear-gradient(225deg, #18c8ff40 14.89%, #933ffe40 85.85%);

    &.modal-wallet-active {
      display: flex;
    }

    .modal-wallet {
      padding: 24px;
      position: absolute;
      width: 35vw;
      height: fit-content;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      background-color: #1a1b23;
      border-radius: 10px;

      @media (max-width: 992px) {
        width: 50vw;
        padding: 18px;
      }

      @media (max-width: 768px) {
        width: 55vw;
      }

      @media (max-width: 576px) {
        width: 80vw;
      }

      .line {
        width: 100%;
        border-bottom: 1px solid white;
        padding-top: 20px;

        @media (max-width: 992px) {
          padding-top: 10px;
        }
      }

      .connect-wallet-title {
        font-size: 24px;

        @media (max-width: 992px) {
          font-size: 20px;
        }
      }

      .connect-wallet-subtitle {
        color: white;
        font-size: 18px;
        padding-top: 30px;

        @media (max-width: 992px) {
          font-size: 16px;
          padding-top: 15px;
          margin: 0;
        }
      }

      .close-button {
        position: absolute;
        right: 0;
        margin-right: 25px;
        transition: all 0.3s;

        &:hover {
          transform: rotate(180deg) scale(1.05);
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;
    backdrop-filter: blur(0);
    transition: backdrop-filter 0.5s ease;
  }

  &.navbar-blur {
    backdrop-filter: blur(5px);
  }

  &.gray {
    background-color: #0b0b0f;
  }

  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .logo {
    width: 235px;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .logo-solo {
    width: 40px;
    display: none;

    @media (max-width: 991px) {
      display: flex;
    }
  }

  .list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    display: flex;
    transition: all 0.3s;
    position: initial;
    height: auto;
    margin: 0;

    @media (max-width: 767px) {
      border-bottom: 1px solid gray;
      border-top: 1px solid gray;
      background-color: #0b0b0f;
      position: absolute;
      left: -100%;
      margin-top: 55px;
      height: 80vh;
      width: 100%;
      justify-content: center;
    }

    &.burguer-active {
      left: 0;
    }
  }

  .navList {
    .social-media-container {
      @media (min-width: 768px) {
        display: none;
      }
      .social-media {
        margin-left: 8px;
        margin-right: 8px;
        width: 30px;
        height: 30px;
      }
    }

    flex-wrap: wrap;
    justify-content: center;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .link-game {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        padding-bottom: 20px;
      }

      a {
        text-decoration: none;
      }

      &.contractContainer {
        transition: all 0.3s;

        .contract {
          position: relative;
          background: #1ba0c9;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-size: 28px;
          padding-left: 15px;
          padding-right: 15px;
          transition: all 0.3s;
          font-weight: 500;

          @media (max-width: 767px) {
            font-size: 50px;
          }

          &::before {
            content: attr(data-text);
            position: absolute;
            top: -4px;
            left: 0;
            -webkit-text-stroke: 1px #af7fee90;
            text-shadow: 0 0 5px #af7fee, 0 0 15px #af7fee;
            z-index: -1;
          }
        }

        &:hover {
          transform: scale(1.06);
        }
      }

      .Navlink {
        background: linear-gradient(225deg, #ffffff 14.89%, #ffffff 85.85%);
        background-clip: text;
        color: white;
        text-decoration: none;
        font-size: 18px;
        transition: all 0.2s;
        padding: 0 15px;
        font-weight: 300;

        @media (max-width: 767px) {
          font-size: 40px;
        }

        &:hover {
          background: linear-gradient(225deg, #18c8ff 14.89%, #933ffe 85.85%);
          background-clip: text;
          color: transparent;
        }
      }
    }
  }

  .button-wallet {
    width: fit-content;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.03);
    }

    a {
      text-decoration: none;
      color: white;
      background-color: transparent;
      text-align: center;
      font-size: 18px;
      transition: all 0.3s;
      border: 1px solid #ffffff;
      border-radius: 20px;
      padding: 8px 14px;
      font-weight: 300;

      &:hover {
        background: linear-gradient(225deg, #18c8ff 14.89%, #933ffe 85.85%);
        background-clip: text;
        color: transparent;
      }
    }
  }

  .bars-container {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;

    @media (min-width: 768px) {
      display: none;
    }

    .bars {
      width: 30px;
    }
  }
}

#WalletItems {
  border: 2px solid #d4d4d860;
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.15);
  height: 100%;
  border-radius: 10px;
  background-color: #1a1b23;
  transition: all 0.3s;
  cursor: pointer;

  &.active {
    transform: scale(1.005);
    box-shadow: 0 3px 10px #10b98150;
    border: 2px solid #10b981;
    background-color: #0b0b0f80;
  }

  &:hover {
    transform: scale(1.005);
    box-shadow: 0 3px 10px #10b98150;
    border: 2px solid #10b981;
    background-color: #0b0b0f80;
  }

  .card-content {
    height: 100%;
    display: flex;
    position: relative;
    margin: 0 10px 0 10px;
    padding: 10px 0 10px 0;

    .card-title {
      font-size: 14px;
      font-weight: 500;
    }

    .icon {
      width: 25px;
    }

    .check-icon {
      position: absolute;
      padding-top: 15px;
      top: 0;
      right: 0;
      opacity: 0;
      transition: all 0.2s;

      &.active {
        opacity: 1;
      }
    }
  }
}
