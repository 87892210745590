#Swap {
  background-color: #0b0b0f;
  height: 100vh;
  width: 100%;

  .swap-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .swap-card {
      height: fit-content;
      width: 450px;
      border-radius: 10px;
      border: 1px solid #fcfcfc;
      background-color: #1a1b23;

      .title-swap {
        color: #ffffff;
        font-weight: 500;
      }

      .subtitle-swap {
        font-size: 14px;
      }

      .line {
        width: 100%;
        border-bottom: 1px solid #e4e4e7;
      }

      .rectangle {
        width: 100%;
        height: 80px;
        border-radius: 10px;
        border: 1px solid #8a8a90;
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 20px;

        .money {
          color: #ffffff;
          font-weight: 500;
          border: none;
          outline: none;
          background-color: transparent;
          padding-left: 20px;
        }

        input[type="number"]:focus {
          border: 0;
          outline: none;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .currency-container {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          padding-right: 20px;
          display: flex;
          align-items: center;

          span {
            color: #ffffff;
            padding-left: 10px;
            font-size: 16px;
          }
        }
      }

      .fee-container {
        position: relative;
        margin-top: 30px;

        .fee-text {
          color: #9295a6;
          font-weight: 500;
        }

        .fee-number {
          color: #ffffff;
          position: absolute;
          font-size: 16px;
          font-weight: 300;
          top: 0;
          right: 0;
        }
      }

      .gray-line {
        width: 100%;
        border-bottom: 1px solid #8a8a90;
      }

      .gradient-button {
        padding-top: 30px;
        width: 100%;
        display: flex;

        a {
          width: 100%;
          border-radius: 7px;
          background: linear-gradient(188deg, #18c8ff90 20%, #923ffe90 80%);
          pointer-events: none;
        }
      }
    }
  }
}
