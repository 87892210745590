#Home {
  height: fit-content;
  overflow: hidden;
  padding-bottom: 100px;
  background-color: #0b0b0f;

  /****************************************************Banner***************************************************/
  #Banner {
    height: 100vh;
    padding-top: 10px;

  
    .banner-elements-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
  
      .Parallax {
        width: 100vw;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
  
      .planet {
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: hola;
        animation-name: hola;
        height: auto;
        left: 10%;
        position: absolute;
        top: 38%;
        width: 400px;
        z-index: 2;
  
        @keyframes hola {
          0% {
            transform: translate(0px, 0px) rotate(-20deg);
          }
          50% {
            transform: translate(8px, -8px) rotate(-20deg);
          }
          100% {
            transform: translate(0px, 0px) rotate(-20deg);
          }
        }
  
        @media (max-width: 1200px) {
          width: 350px;
        }
  
        @media (max-width: 1024px) {
          width: 300px;
        }
  
        @media (max-width: 991px) {
          top: 50%;
          width: 250px;
        }
  
        @media (max-width: 768px) {
          left: 5%;
          top: 25%;
          width: 200px;
        }
      }
  
      .glare {
        z-index: 2;
        position: absolute;
  
        @media (max-width: 768px) {
          transform: scale(0.7);
        }
  
        &.one {
          right: 15%;
          top: 20%;
        }
  
        &.two {
          bottom: 10%;
          left: 10%;
        }
  
        &.three {
          bottom: 5%;
          right: 5%;
        }
  
        &.four {
          top: 55%;
          left: 60%;
        }
  
        &.five {
          left: 95%;
          top: 80%;
        }
  
        &.six {
          right: 5%;
          top: 50%;
        }
  
        &.seven {
          left: 10%;
          top: 80%;
        }
  
        &.eight {
          left: 5%;
          top: 10%;
        }
  
        &.nine {
          right: 5%;
          top: 40%;
        }
  
        &.ten {
          left: 20%;
          top: 10%;
        }
  
        &.eleven {
          right: 15%;
          bottom: 20%;
        }
      }
  
      .star {
        z-index: 2;
        position: absolute;
  
        @media (max-width: 768px) {
          transform: scale(0.7);
        }
  
        &.one {
          right: 8%;
          top: 20%;
        }
  
        &.two {
          bottom: 35%;
          left: 50%;
        }
  
        &.three {
          bottom: 20%;
          right: 15%;
        }
  
        &.four {
          left: 2%;
          top: 20%;
        }
      }
    }
  
    .herobanner {
      background-image: url("../../assets/Banner/bannerImg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-attachment: fixed;
  
      @media (max-width: 768px) {
        background-attachment: scroll;
        background-position: center;
      }
    }

  
    .title-banner,
    .new {
      font-size: 50px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      color: white;
      font-weight: bold;
      z-index: 10;
      position: relative;
  
      @media (max-width: 1024px) {
        font-size: 40px;
      }
  
      @media (max-width: 768px) {
        font-size: 30px;
      }
  
      &.new {
        background: linear-gradient(180deg, #18c8ff 30%, #933ffe 70%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }
  
    .text-banner {
      z-index: 10;
      position: relative;
      color: #ffffff;
      border-radius: 5px;
      font-size: 18px;
  
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  
    .gradient-button {
      z-index: 10;
      position: relative;
    }
  
    .gradient-button.large-button a {
      display: inline-block;
      padding: 12px 24px;
      font-size: 18px;
      text-decoration: none;
      color: #ffffff;
      background: linear-gradient(90deg, #18c8ff 0%, #933ffe 100%);
      border-radius: 6px;
      transition: all 0.3s ease;
  
      @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 16px;
      }
    }
  
    .gradient-button.small-button a {
      display: inline-block;
      padding: 8px 12px;
      font-size: 14px;
      text-decoration: none;
      color: #ffffff;
      background: linear-gradient(90deg, #18c8ff 0%, #933ffe 100%);
      border-radius: 4px;
      transition: all 0.3s ease;
  
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 6px 10px;
      }
    }
  
    .gradient-button a:hover {
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  
    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      flex-wrap: wrap;
  
      .gradient-button {
        margin: 5px;
      }
    }
  
    .right-aligned-content-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      position: relative;
      top: 10%;
      right: 12%;
      z-index: 10;
      
      @media (max-width: 1024px) {
        justify-content: center;
        right: 0;
      }
      
      @media (max-width: 768px) {
        top: auto;
        bottom: 5%;
        left: 0;
        right: 0;
        justify-content: center;
      }
  
      @media (max-height: 700px) {
        bottom: 2%;
      }
    }
  
    .right-aligned-text-wrapper {
      text-align: center;
      max-width: 400px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      overflow-y: auto;
      max-height: 80vh;
  
      @media (max-width: 768px) {
        max-width: 90%;
        padding: 10px;
      }
  
      @media (max-height: 700px) {
        max-height: 70vh;
      }
    }
  
    .right-aligned-text {
      margin-bottom: 10px;
      font-size: 16px;
  
      @media (max-width: 768px) {
        font-size: 14px;
      }
  
      @media (max-height: 700px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  
    .collaboration-section,
    .schedule-section {
      margin-top: 10px;
      width: 100%;
  
      @media (max-height: 700px) {
        margin-top: 5px;
      }
    }
  
    .collaboration-logos-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      flex-wrap: wrap;
      padding: 0 10px;  // Added this line
    }
  
    .collaboration-logo {
      max-height: 30px;
      width: auto;
      object-fit: contain;
      margin: 0 20px;  // Increased from 10px to 20px
    
      @media (max-width: 768px) {
        max-height: 25px;
        margin: 20px;  // Increased from 5px to 10px
      }
    
      @media (max-height: 700px) {
        max-height: 20px;
        margin: 8px;  // Added this line to maintain spacing on shorter screens
      }
    }
  
    .schedule-section p {
      font-size: 14px;
      margin-bottom: 5px;
  
      @media (max-width: 768px) {
        font-size: 12px;
      }
  
      @media (max-height: 700px) {
        font-size: 11px;
        margin-bottom: 3px;
      }
    }
  
    .right-aligned-text-wrapper,
    .collaboration-section,
    .schedule-section,
    .buttons-container {
      z-index: 10;
      position: relative;
      color: #ffffff;
    }
  
    @media (max-width: 768px) {
      .gradient-button.small-button {
        display: inline-block;
      }
    }
  }
  /****************************************************Future***************************************************/

  #Future {
    height: fit-content;
    padding-top: 200px;
    position: relative;
    z-index: 1;

    .wave {
      position: absolute;
      left: -90%;
      top: 40%;
      z-index: -1;
    }

    .title-future {
      font-size: 70px;
      color: white;
      font-weight: bold;

      @media (max-width: 1024px) {
        font-size: 60px;
      }

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }
  }

  #CardItems {
    border: 1px solid #ffffff;
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.15);
    margin: 5px;
    height: 100%;
    border-radius: 25px;
    background-color: #1a1b23;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 3px 10px rgba(255, 255, 255, 0.329);
    }

    .card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .icon-crypto {
        padding-top: 24px;

        @media (max-width: 500px) {
          padding-top: 10px;
        }
      }

      .card-title {
        font-size: 26px;
        font-weight: bolder;
        padding-top: 10px;

        @media (max-width: 500px) {
          padding-top: 5px;
          font-size: 22px;
        }
      }

      .card-sub-title {
        background: linear-gradient(185deg, #18c8ff 14.89%, #933ffe 85.85%);
        background-clip: text;
        color: transparent;
        font-weight: bold;
        padding-top: 10px;
        margin-bottom: 0;

        @media (max-width: 500px) {
          padding-top: 5px;
          font-size: 17px;
        }
      }

      .card-text {
        font-size: 16px;
        padding-top: 10px;

        @media (max-width: 500px) {
          font-size: 15px;
        }
      }

      .button-card {
        transition: all 0.3s;
        width: fit-content;
        padding-top: 16px;
        padding-bottom: 48px;
        text-decoration: none;

        @media (max-width: 500px) {
          padding-top: 10px;
          padding-bottom: 25px;
        }

        &:hover {
          transform: scale(1.05);
        }
        div {
          color: white;
          padding: 10px 25px;
          border-radius: 10px;
          background: linear-gradient(200deg, #18c8ff 14.89%, #933ffe 85.85%);
          text-align: center;
          font-size: 18px;
          font-weight: 400;

          @media (max-width: 500px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  /****************************************************Projects***************************************************/

  #Projects {
    padding-top: 100px;
    position: relative;
    z-index: 1;
    .title-projects {
      font-size: 50px;
      color: #ffffff;
      font-weight: bolder;

      @media (max-width: 1024px) {
        font-size: 40px;
      }

      @media (max-width: 768px) {
        font-size: 35px;
      }

      span {
        font-size: 50px;

        @media (max-width: 1024px) {
          font-size: 40px;
        }

        @media (max-width: 768px) {
          font-size: 35px;
        }
      }
    }
  }

  #ProjectItems {
    border: 1px solid #ffffff;
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.15);
    margin: 5px;
    height: 100%;
    border-radius: 25px;
    background-color: #1a1b23;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 3px 10px rgba(255, 255, 255, 0.329);
    }

    .card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding-left: 24px;
      padding-right: 24px;

      @media (max-width: 500px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .img-project {
        padding-top: 24px;

        @media (max-width: 500px) {
          padding-top: 16px;
        }

        img {
          width: 50%;
          border-radius: 25px;
          border: 1px solid rgba(128, 128, 128, 0.795);
        }

        iframe {
          width: 100%;
          border-radius: 25px;
          border: 1px solid rgba(128, 128, 128, 0.795);
        }
      }

      .card-title {
        font-size: 32px;
        font-weight: bolder;
        padding-top: 20px;

        @media (max-width: 500px) {
          padding-top: 5px;
          font-size: 21px;
        }
      }

      .card-sub-title {
        background: linear-gradient(185deg, #18c8ff 14.89%, #933ffe 85.85%);
        background-clip: text;
        color: transparent;
        font-weight: 500;
        padding-top: 20px;
        margin-bottom: 0;
        width: fit-content;

        @media (max-width: 500px) {
          padding-top: 10px;
          font-size: 16px;
        }
      }

      .card-text {
        font-size: 16px;
        padding-top: 15px;

        @media (max-width: 500px) {
          font-size: 15px;
        }
      }

      .button-card {
        transition: all 0.3s;
        width: fit-content;
        padding-top: 20px;
        padding-bottom: 48px;

        @media (max-width: 500px) {
          padding-top: 15px;
          padding-bottom: 25px;
        }

        &:hover {
          transform: scale(1.05);
        }
        a {
          text-decoration: none;
          color: white;
          padding: 15px 25px;
          border-radius: 10px;
          background: linear-gradient(200deg, #18c8ff 14.89%, #933ffe 85.85%);
          text-align: center;
          font-size: 18px;
          font-weight: 400;

          @media (max-width: 500px) {
            font-size: 16px;
            padding: 10px 18px;
          }
        }
      }
    }
  }

  /****************************************************Experience***************************************************/

  #Experience {
    margin-top: 200px;

    p {
      @media (max-width: 991px) {
        text-align: center;
      }
    }

    .img-container {
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 1px 10px rgba(255, 255, 255, 0.15);
      z-index: 10;

      .blockchain-img {
        height: 100%;
        cursor: pointer;
        transition: all 0.4s;
        z-index: 10;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .blockchain-button {
      width: fit-content;
      transition: all 0.3s;

      @media (max-width: 991px) {
        margin-left: auto;
        margin-right: auto;
      }

      &:hover {
        transform: scale(1.03);
      }

      a {
        text-decoration: none;
        color: white;
        background-color: transparent;
        text-align: center;
        font-size: 18px;
        transition: all 0.3s;
        border: 1px solid #ffffff;
        border-radius: 10px;
        padding: 15px 22px;
        font-weight: 300;
        pointer-events: none;
      }
    }

    .title-experience {
      font-size: 64px;
      color: #ffffff;
      font-weight: bolder;
      font-family: "Bebas Neue", sans-serif;

      @media (max-width: 1024px) {
        font-size: 50px;
      }

      @media (max-width: 991px) {
        text-align: center;
        padding-top: 30px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
      }

      span {
        font-size: 64px;
        font-family: "Bebas Neue", sans-serif;

        @media (max-width: 1024px) {
          font-size: 50px;
        }

        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
    }
  }

  /****************************************************Play***************************************************/

  #Play {
    padding-top: 150px;

    .title-play,
    .play-gradient {
      font-size: 70px;
      color: white;
      font-weight: bold;
      display: inline-block;
      z-index: 2;

      @media (max-width: 1024px) {
        font-size: 60px;
      }

      @media (max-width: 768px) {
        font-size: 50px;
      }

      &.play-gradient {
        background: linear-gradient(180deg, #18c8ff 35%, #933ffe 65%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }

    .content-play {
      position: relative;
      z-index: 2;

      @media (max-width: 991px) {
        padding-top: 50px;
      }

      .title-play {
        width: 100%;

        @media (max-width: 991px) {
          text-align: center;
        }
      }

      .text {
        font-size: 17px;
        @media (max-width: 991px) {
          text-align: center;
        }
      }

      .gradient-button {
        @media (max-width: 991px) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      h3 {
        font-family: "Bebas Neue", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 26px;

        @media (max-width: 991px) {
          text-align: center;
        }
      }

      .bars-container {
        @media (max-width: 991px) {
          text-align: center;
        }
        .bar {
          display: inline-block;
          width: 85px;
          height: 8px;
          border-radius: 5px;
          background: linear-gradient(225deg, #18c8ff 14.89%, #933ffe 85.85%);
        }

        .point {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: linear-gradient(300deg, #933ffe 14.89%, #18c8ff 85.85%);
          margin-left: 10px;
        }
      }
    }
  }

  /****************************************************JoinUs***************************************************/

  #JoinUs {
    padding-top: 100px;

    .title-JoinUs {
      font-size: 64px;
      color: #ffffff;
      font-weight: bolder;
      font-family: "Bebas Neue", sans-serif;

      @media (max-width: 1024px) {
        font-size: 50px;
      }

      @media (max-width: 991px) {
        text-align: center;
        padding-top: 30px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
      }

      span {
        font-size: 64px;
        font-family: "Bebas Neue", sans-serif;

        @media (max-width: 1024px) {
          font-size: 50px;
        }

        @media (max-width: 768px) {
          font-size: 40px;
        }
      }
    }

    .discord-link {
      text-decoration: none;
      transition: all 0.5s;
      background: linear-gradient(190deg, #18c8ff 30%, #933ffe 70%);
      background-clip: text;
      color: #898ca9;
      display: inline-block;

      &:hover {
        background: linear-gradient(190deg, #18c8ff 30%, #933ffe 70%);
        background-clip: text;
        color: transparent;
        display: inline-block;
      }
    }

    .social-media-container {
      border-radius: 15px;
      border: 1px solid #ffffff;
      background-color: #1a1b23;
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 60px;
      margin-bottom: 40px;
      flex-wrap: wrap;

      @media (max-width: 600px) {
        padding-top: 10px;
      }

      a {
        text-decoration: none;
      }

      .description {
        color: #ffffff;
        font-family: "Bebas Neue", sans-serif;
        font-weight: bold;
        font-size: 20px;
        padding-top: 5px;
        transition: all 0.3s;

        &:hover {
          transform: translateY(-5%);
        }
      }

      .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: 600px) {
          width: 25%;
        }

        .social-media {
          width: 30px;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1) rotate(15deg);
          }
        }
      }
    }
  }
}
