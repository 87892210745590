#Footer {
  background-color: #1a1b23;
  border-top: 1px solid #ffffff;
  padding-top: 60px;

  .footer-wrapper {
    padding-bottom: 60px;

    .stellargate-logo {
      width: 255px;
      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .phantasma-logo {
      width: 180px;

      @media (max-width: 1199px) {
        padding-top: 70px;
      }

      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 0;
        padding-bottom: 30px;
      }
    }

    .footer-text {
      @media (min-width: 200px) {
        font-size: 16px;
      }

      @media (max-width: 991px) {
        text-align: center;
        padding-bottom: 5px;
      }
    }

    ul {
      .title-footer {
        background: linear-gradient(225deg, #18c8ff 14.89%, #933ffe 85.85%);
        background-clip: text;
        color: transparent;
        transition: all 0.3s;
        font-size: 20px;
        width: fit-content;

        &::before {
          content: "";
          bottom: 0;
          left: 0;
          width: 100%;
          border-bottom: 1px solid white;
          position: absolute;
          transition: all 0.3s;
        }

        @media (max-width: 575px) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .link-wrapper {
        width: fit-content;
        position: relative;

        @media (max-width: 575px) {
          margin-left: auto;
          margin-right: auto;
        }

        li {
          list-style: none;
          transition: all 0.3s;
          padding-top: 4px;

          &::before {
            content: "";
            bottom: 0;
            left: 0;
            width: 0%;
            border-bottom: 1px solid white;
            position: absolute;
            opacity: 0;
            transition: all 0.3s;
          }

          &:hover::before {
            opacity: 1;
            width: 100%;
          }

          a {
            text-decoration: none;
            color: #898ca9;
            transition: all 0.3s;

            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    border-top: 1px solid #ffffff;
  }
}
