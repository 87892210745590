.bg-circles {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
}

#Road-map {
  background-color: #0b0b0f;
  height: fit-content;
  overflow: hidden;

  #Banner {
    height: 100vh;
    position: relative;

    .star {
      position: absolute;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .one {
      left: -30%;
      top: 0;
      width: 100px;
    }

    .two {
      left: 38%;
      top: 350px;
      width: 80px;
    }

    .Parallax {
      width: 100vw;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    .title-banner,
    .map {
      font-size: 120px;
      color: white;
      font-weight: bold;
      padding-top: 130px;
      z-index: 10;
      position: relative;

      @media (max-width: 1024px) {
        font-size: 100px;
      }

      @media (max-width: 768px) {
        font-size: 80px;
      }

      &.map {
        background: linear-gradient(180deg, #18c8ff 35%, #933ffe 65%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }

    .arrow-container {
      margin-top: 70px;
      position: relative;
      .arrow,
      .arrow::before,
      .arrow::after {
        position: absolute;
        left: 50%;
        z-index: 10;
      }

      .arrow {
        width: 60px;
        height: 60px;
        top: 50%;
        margin: -20px 0 0 -20px;
        transform: rotate(45deg) translateX(-20%);
        border-left: none;
        border-top: none;
        border-right: 3px #ffffff90 solid;
        border-bottom: 3px #ffffff90 solid;
        transition: all 0.3s;
        border-radius: 50%;

        &:hover {
          border-right: 3px #ffffff solid;
          border-bottom: 3px #ffffff solid;

          &::before {
            border-right: 2px #ffffff solid;
            border-bottom: 2px #ffffff solid;
          }

          &::after {
            border-right: 1px #ffffff solid;
            border-bottom: 1px #ffffff solid;
          }
        }
      }

      .arrow:before {
        content: "";
        width: 25px;
        height: 25px;
        top: 50%;
        margin: -11px 0 0 1px;
        border-left: none;
        border-top: none;
        border-right: 2px #ffffff90 solid;
        border-bottom: 2px #ffffff90 solid;
        transition: all 0.3s;
        transform: translateX(-50%);
      }

      .arrow::after {
        content: "";
        width: 15px;
        height: 15px;
        top: 50%;
        margin: -9px 0 0 -9px;
        border-left: none;
        border-top: none;
        border-right: 1px #ffffff90 solid;
        border-bottom: 1px #ffffff90 solid;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: arrow;
      }

      @keyframes arrow {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
          transform: translate(-10px, -10px);
        }
      }
    }
  }

  #Products {
    position: relative;
    margin-top: 50px;

    .products-container {
      margin-bottom: 150px;
    }

    #ProductItems {
      height: 100%;
      width: 100%;
      position: relative;

      @media (max-width: 767px) {
        width: 80%;
      }

      .phase-container {
        display: flex;
        justify-content: center;

        .shape {
          width: 115%;
        }
      }

      .card-content {
        position: relative;
      }

      .circle {
        position: absolute;
        left: 50%;
        top: -90px;
        transform: translateX(-50%);
      }

      .card-title {
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        color: #ffffff;
        position: absolute;
        top: 20px;

        .done {
          color: rgb(80, 228, 80);
          font-size: 20px;
        }

        @media (max-width: 375px) {
          top: 10px;
        }
      }

      .card-text {
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0;

        @media (max-width: 500px) {
          font-size: 15px;
        }
      }

      .card-text-container {
        margin-top: 50px;

        .card-part {
          position: relative;
          margin-top: 30px;

          .card-subtitle {
            font-size: 21px;
            color: #ffffff;
            margin-bottom: 10px;
          }

          .bar {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 85%;
            width: 10px;
            left: -15px;
            margin-left: -5px;
          }

          .bar2 {
            left: -15px;
            position: absolute;
            bottom: 0;
            height: 85%;
            width: 9px;
            margin-left: -5px;
          }
        }
      }
    }
  }
}
