#Policy {
  height: fit-content;
  overflow: hidden;
  padding-bottom: 100px;
  background-color: #0b0b0f;

  /* ---------------------------------------------------- Banner ---------------------------------------------------- */

  #Banner {
    height: 80vh;
    padding-top: 80px;

    .title-banner,
    .new {
      font-size: 96px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      color: white;
      font-weight: bold;
      z-index: 10;
      position: relative;

      @media (max-width: 1024px) {
        font-size: 86px;
      }

      @media (max-width: 768px) {
        font-size: 70px;
      }

      &.new {
        background: linear-gradient(180deg, #18c8ff 30%, #933ffe 70%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }

    .text-banner {
      z-index: 10;
      position: relative;
      color: #898ca9;
      border-radius: 5px;
    }

    .gradient-button {
      z-index: 10;
      position: relative;
    }
  }

  /* ---------------------------------------------------- Privacy-policy ---------------------------------------------------- */

  #Privacy-policy {
    height: fit-content;

    .title-privacy,
    .gradient-word {
      width: fit-content;
      color: white;
      position: relative;
      font-size: 30px;
      font-weight: bold;

      .gradient-word {
        background: linear-gradient(180deg, #18c8ff 30%, #933ffe 70%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }

    .title-privacy {
      padding-top: 20px;
    }

    p {
      margin: 0;
    }
  }
}
