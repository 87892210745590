#Whitepaper {
  height: fit-content;
  overflow: hidden;
  padding-bottom: 100px;
  background-color: #0b0b0f;

  #Banner {
    .title-banner,
    .dao {
      font-size: 120px;
      color: white;
      font-weight: bold;
      padding-top: 80px;

      @media (max-width: 1024px) {
        font-size: 100px;
      }

      @media (max-width: 768px) {
        font-size: 80px;
      }

      &.dao {
        background: linear-gradient(180deg, #18c8ff 35%, #933ffe 65%);
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-family: "Bebas Neue", sans-serif;
        padding-top: 0;
      }
    }
  }
}
