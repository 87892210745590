#Token {
  height: fit-content;
  overflow: hidden;
  background-color: #1a1b23;

  .sky {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    @media (max-width: 991px) {
      height: 70vh;
    }
  }
  .CITsky {
    height: 100vh;
    object-fit: cover;
    position: absolute;
    z-index: 0;

    @media (max-width: 991px) {
    width: 150vw;
    height: 100vh;
    }
  }

  .CITBanner {
    position: relative;
    justify-self: center;
    overflow: hidden;
    z-index: 1;
    

    @media (max-width: 991px) {
      height: 150vh;
    }

    @media (max-width: 768px) {
      height: 100vh !important;
      left: -100px;
      width: 150vw;
    }

    .Parallax {
      position: absolute;
      left: 0;
      top: 0%;
      width: 100%;
      height: 100vh !important;

      @media (max-width: 768px) {
        top: 5%;
      }

      .mountain {
        width: 100%;
        height: 100vh !important;
      }
    }
  }

  .Banner {
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media (max-width: 991px) {
      height: 70vh;
    }

    @media (max-width: 768px) {
      height: 60vh;
    }

    .Parallax {
      position: absolute;
      left: 0;
      top: 0%;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        top: 5%;
      }

      .mountain {
        width: 100%;
        height: 100%;
      }
    }

    .logo-container {
      position: absolute;
      top: 1%;
      left: 50%;
      transform: translateX(-50%);
      width: 220px;

      @media (max-width: 768px) {
        width: 80px;
        top: 8%;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }

    .content {
      position: relative;
      margin-top: 60px;

      @media (max-width: 768px) {
        margin-top: 30px;
      }

      .token-title {
        font-size: 28px;
        color: #ffffff;
        font-weight: 300;
        font-family: inter;

        @media (max-width: 768px) {
          font-size: 22px;
        }

        .launch {
          font-size: 28px;
          font-weight: 700;
          background: linear-gradient(190deg, #18c8ff 10%, #933ffe 70%);
          background-clip: text;
          color: transparent;
          display: inline-block;
          padding-top: 0;

          @media (max-width: 768px) {
            font-size: 22px;
          }
        }
      }

      .time-container {
        position: relative;

        .time {
          font-size: 130px;
          font-weight: 600;
          font-family: inter;
          text-shadow: 2px 0px 10px #ffffff;

          @media (max-width: 1200px) {
            font-size: 100px;
          }

          @media (max-width: 991px) {
            font-size: 80px;
          }

          @media (max-width: 768px) {
            font-size: 50px;
          }

          @media (max-width: 425px) {
            font-size: 40px;
          }
        }

        .time-raa {
          text-decoration: none;
          z-index: 200;
          font-size: 40px;
          font-weight: 600;
          font-family: inter;
          text-shadow: 2px 0px 10px #ffffff;

          @media (max-width: 1200px) {
            font-size: 30px;
          }

          @media (max-width: 991px) {
            font-size: 20px;
          }

          @media (max-width: 768px) {
            font-size: 17px;
          }

          @media (max-width: 425px) {
            font-size: 14px;
          }
        }

        .rectangle {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        .time-text {
          span {
            color: #ffffff;
            font-weight: 500;
            font-size: 26px;
            width: 270px;
            display: flex;
            justify-content: center;

            @media (max-width: 1200px) {
              width: 200px;
            }

            @media (max-width: 991px) {
              width: 150px;
            }

            @media (max-width: 768px) {
              width: 100px;
              font-size: 18px;
            }

            @media (max-width: 425px) {
              width: 80px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }


  .time-cit {
    text-decoration: none;
    z-index: 200;
    font-size: 40px;
    font-weight: 600;
    font-family: inter;
    text-shadow: 2px 0px 10px #ffffff;

    @media (max-width: 1200px) {
      font-size: 30px;
    }

    @media (max-width: 991px) {
      font-size: 20px;
    }

    @media (max-width: 768px) {
      font-size: 17px;
    }

    @media (max-width: 425px) {
      font-size: 14px;
    }
  }



  .Banner-C {
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media (max-width: 991px) {
      height: 100vh;
    }

    @media (max-width: 768px) {
      height: 100vh;
    }

    .Parallax {
      position: absolute;
      left: 0;
      top: 0%;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        top: 5%;
      }

      .mountain {
        width: 100%;
        height: 100%;
      }
    }


    .content-cit {
      position: relative;
      margin-top: 40vh;

      @media (max-width: 768px) {
        position: relative;
        left: -5%;
        margin-top: 40vh;
      }

      @media (max-width: 500px) {
        position: relative;
        margin-top: 40vh;
      }

      .token-title {
        font-size: 28px;
        color: #ffffff;
        font-weight: 300;
        font-family: inter;

        @media (max-width: 768px) {
          font-size: 22px;
        }

        .launch {
          font-size: 28px;
          font-weight: 700;
          background: linear-gradient(190deg, #18c8ff 10%, #933ffe 70%);
          background-clip: text;
          color: transparent;
          display: inline-block;
          padding-top: 0;

          @media (max-width: 768px) {
            font-size: 22px;
          }
        }
      }

      .time-container {
        position: relative;

        .time {
          font-size: 130px;
          font-weight: 600;
          font-family: inter;
          text-shadow: 2px 0px 10px #ffffff;

          @media (max-width: 1200px) {
            font-size: 100px;
          }

          @media (max-width: 991px) {
            font-size: 80px;
          }

          @media (max-width: 768px) {
            font-size: 50px;
          }

          @media (max-width: 425px) {
            font-size: 40px;
          }
        }

        .rectangle {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        .time-text {
          span {
            color: #ffffff;
            font-weight: 500;
            font-size: 26px;
            width: 270px;
            display: flex;
            justify-content: center;

            @media (max-width: 1200px) {
              width: 200px;
            }

            @media (max-width: 991px) {
              width: 150px;
            }

            @media (max-width: 768px) {
              width: 100px;
              font-size: 18px;
            }

            @media (max-width: 425px) {
              width: 80px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .logo-container-cit {
    position: absolute;
    top: 7%;
    display: flex;
    justify-content: center;
    width: 500px;


    @media (max-width: 768px) {
      width: 600px;
      left: 10%;
    }

    @media (max-width: 500px) {
      left: 0%;
    }

    img {
      cursor: pointer;
      width: 50%;
    }
  }

  #Launch {
    .launching-text {
      font-size: 30px;
      font-weight: 500;
      background: linear-gradient(190deg, #18c8ff 10%, #933ffe 70%);
      background-clip: text;
      color: transparent;
      display: inline-block;
      padding-top: 0;

      @media (max-width: 768px) {
        font-size: 26px;
      }
    }

    .launching-sub-text {
      font-size: 22px;
      font-weight: 500;
      background: linear-gradient(190deg, #18c8ff 10%, #933ffe 70%);
      background-clip: text;
      color: transparent;
      display: inline-block;
      padding-top: 0;

      @media (max-width: 768px) {
        font-size: 17px;
      }
    }

    .percentage-space{
      display: flex;
      justify-content: center;
      min-width: 50px;
      margin-left: 5px;
    }

    .From-space{
      display: flex;
      min-width: 100px;
      margin-left: 5px;
    }

    .logos-container {
      display: flex;
      justify-content: space-around;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 50px;
      }
      .logo {
        width: 400px;

        @media (max-width: 991px) {
          width: 300px;
        }

        @media (max-width: 768px) {
          width: 250px;
        }
      }
    }
  }
}
.logoCit{
  width: 350px;
  height: 350px;

  @media (max-width: 991px) {
  height: 300px;
  width: 300px;
  }

  @media (max-width: 768px) {
  height: 250px;
  width: 250px;
  }
}

.text-nowrap{
  text-wrap: nowrap;
}

.logoCS{
  width: 125px;
  height: 125px;

  @media (max-width: 991px) {
  height: 100px;
  width: 100px;
  }

  @media (max-width: 768px) {
  height: 90px;
  width: 90px;
  }
}

  .logoCSP{
    width: 110px;
    height: 125px;
  
    @media (max-width: 991px) {
    height: 100px;
    width: 115px;
    }
  
    @media (max-width: 768px) {
    height: 90px;
    width: 100px;
    }
}

  .initial-allocation{
    p{
      color: white !important;
    }
  }

  .raa-chart-tax{
    p{
      color: white;
    }
  }