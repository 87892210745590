//components
@import "../styles/pages/home.scss";
@import "../styles/pages/aboutUs.scss";
@import "../styles/pages/notFound.scss";
@import "../styles/pages/footer.scss";
@import "../styles/pages/header.scss";
@import "../styles/pages/comingSoon.scss";
@import "../styles/pages/roadMap.scss";
@import "../styles/pages/swap.scss";
@import "../styles/pages/policy.scss";
@import "../styles/pages/Whitepaper.scss";
@import "../styles/pages/game.scss";
@import "../styles/pages/token.scss";

* {
  scroll-behavior: smooth;

  body::-webkit-scrollbar {
    width: 5px;
    background-color: #0b0b0f;
  }

  body::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.5);
    border-radius: 8px;

    &:hover {
      background: rgba(128, 128, 128, 0.75);
    }
  }
}

*::selection {
  background-color: transparent;
}

/* h2 */
h1 {
  @media (min-width: 200px) {
    font-size: xx-large;
  }

  @media (min-width: 768px) {
    font-size: 40px;
  }

  @media (min-width: 1280px) {
    font-size: 55px;
  }
}

/* h2 */
h2 {
  @media (min-width: 200px) {
    font-size: x-large;
  }

  @media (min-width: 768px) {
    font-size: xx-large;
  }

  @media (min-width: 1280px) {
    font-size: 45px;
  }
}

/* h3 */
h3 {
  @media (min-width: 1024px) {
    font-size: 20px;
  }

  @media (min-width: 1281px) {
    font-size: 24px;
  }
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  color: white;
}

p,
span,
li,
a {
  font-family: "Inter", sans-serif;
  color: #898ca9;

  @media (min-width: 200px) {
    font-size: 16px;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.gradient-word {
  background: linear-gradient(180deg, #18c8ff 30%, #933ffe 70%);
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/*Button general Style*/
.gradient-button {
  transition: all 0.3s;
  width: fit-content;

  &:hover {
    transform: scale(1.02);
  }
  a {
    text-decoration: none;
    color: white;
    padding: 16px 35px;
    border-radius: 10px;
    background: linear-gradient(225deg, #18c8ff 14.89%, #933ffe 85.85%);
    text-align: center;

    @media (max-width: 768px) {
      padding: 10px 35px;
    }
  }
}
