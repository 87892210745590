#Game {
  min-height: 100vh;
  background-color: #1a1b23;

  .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 2rem 3rem;
    text-align: center;
    color: #fff;
    box-shadow: 0px 0px 25px #222;
    backdrop-filter: blur(8px);
    z-index: 1;
  }

  .bg-image {
    background-image: url("../../assets/Banner/bannerImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;

    @media (max-width: 768px) {
      background-attachment: scroll;
    }
  }

  .content-wrapper {
    width: 100%;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .game-container {
      position: relative;
      z-index: 10;
      width: 900px;
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      position: relative;
      overflow: hidden;

      .square {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100px;
        height: 20px;
        background-color: #333333;
      }

      &.expand {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        overflow: hidden;
        padding: 0;
      }

      &.expand .game {
        border-radius: 0;
      }

      .game {
        border-radius: 5px;
      }

      .icon-container {
        position: absolute;
        bottom: 0;
        right: 0;

        .icon {
          width: 40px;
          margin: 5px 10px;
          cursor: pointer;

          &::after {
            content: "";
          }

          &:hover {
            &::after {
              content: "dvcdsavcsdvds";
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}


.title-game {
  text-decoration: none;
  z-index: 200;
  font-size: 40px;
  font-weight: 600;
  font-family: inter;
  text-shadow: 2px 0px 10px #ffffff;

  @media (max-width: 1200px) {
    font-size: 30px;
  }

  @media (max-width: 991px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 17px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.plat-logo-w{
  height: 200px;
  padding-bottom: 30px;
}

.plat-logo-m{
  height: 180px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.disclaimer-text{
  position: relative;
  top: 20%;

  @media (max-width: 790px) {
  top: 10%;
  }
}